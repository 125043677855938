<template>
    <vx-card title="Attach Work Order">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full mb-base">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Work Order ID </span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <vs-input class="w-full" v-model="code" disabled />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Work Order Description</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <vs-input class="w-full" v-model="description" disabled />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Bill Of Material</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <vs-input class="w-full" v-model="bom" disabled />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Purpose</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <vs-input class="w-full" v-model="purpose" disabled />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Warehouse</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <vs-input class="w-full" v-model="warehouse" disabled />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Request Date</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <vs-input class="w-full" type="input" v-model="requestDate" disabled></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Execution Date</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <vs-input class="w-full" type="input" v-model="executionDate" disabled></vs-input>
                    </div>
                </div>
            </div>
            <div class="vx-col sm:w-1/2 w-full mb-base">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Note</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <vs-textarea v-model="note" class="w-full" type="date" name="note" disabled></vs-textarea>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <label>Attachment
                            <small style="color: red">(only: xlsx, xls, jpg, jpeg, png, pdf, mp4, avi, mkv, 3gp, m4v,
                                wmv, mov)</small></label>
                    </div>
                    <div class="vx-col sm:w-3/4 w-full">
                        <div class="vx-col w-1/2">
                            <!-- only accept "jpg", "jpeg", "pdf", "doc", "docx", "png" , "xlsx", "xls"-->
                            <input id="fileInput" name="file" class="w-full inputx" type="file" ref="file"
                                multiple="multiple"
                                accept=".xlsx, .xls, .jpg, .jpeg, .png, .pdf, .mp4, .avi, .mkv, .3gp, .m4v, .wmv, .mov" />

                        </div>
                    </div>
                </div>
                <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span></span>
                    </div>
                    <div class="vx-col sm:w-3/4 w-full">
                        <div class="vx-col sm:w-4/5 w-full">
                            <vs-button class="mr-3 mb-2" @click="handleAttachment">Add Attachment</vs-button>
                        </div>
                    </div>
                </div>
                <vs-divider style="width: 60%; margin-left: 30%">
                    List Attachment
                </vs-divider>
                <div class="vx-row mb-3 mt-6" style="width: 60%; margin-left: 30%">
                    <table class="vs-table vs-table--tbody-table">
                        <template>
                            <tr class="tr-values vs-table--tr tr-table-state-null selected" v-bind:key="tr.NameFile"
                                v-for="(tr, i) in fileAttachment">
                                <td class="td vs-table--td">{{ tr.file_name }}</td>
                                <td class="td vs-table--td">
                                    <vx-tooltip text="Show" v-if="tr.file_path != ''">
                                        <vs-button type="line" icon-pack="feather" icon="icon-eye"
                                            @click.stop="handleShowAttachment(tr)" />
                                    </vx-tooltip>
                                </td>
                                <td class="td vs-table--td">
                                    <template>
                                        <vx-tooltip text="Delete" v-if="tr.new_attachment == true">
                                            <vs-button type="line" icon-pack="feather" icon="icon-trash"
                                                @click.stop="handleDeleteAttachment(i)" />
                                        </vx-tooltip>
                                    </template>
                                </td>
                            </tr>
                        </template>
                    </table>
                </div>
            </div>
        </div>
        <h3><b>Parent (Result)</b></h3>
        <hr />
        <br />
        <div class="vx-row">
            <div class="vx-col sm:w-1/1 w-full mb-base m-10">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="20%">SKU Code</th>
                            <th width="30%">SKU Name</th>
                            <th width="7%">Qty</th>
                            <th width="7%">Unit</th>
                            <th width="7%">Qty UOM</th>
                            <th width="15%">MAP</th>
                            <th width="10%">Sub Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input class="w-full" v-model="itemParent.sku_code" disabled />
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input class="w-full" v-model="itemParent.name" disabled />
                            </td>
                            <td class="td vs-table--td" style="padding-right: 10px">
                                <vs-input class="w-full" type="number" v-model="itemParent.quantity" :min="1"
                                    disabled />
                            </td>

                            <td class="td vs-table--td" style="padding-right: 5px">
                                <vs-input class="w-full" v-model="itemParent.unit" disabled />
                            </td>
                            <td class="td vs-table--td" style="padding-right: 10px">
                                <vs-input class="w-full" type="number" v-model="itemParent.qty_uom" :min="1" disabled />
                            </td>

                            <td class="td vs-table--td" style="padding-right: 10px">
                                <vs-input class="w-full" type="text" :min="1" :value="formattedItemParentPrice"
                                    disabled />
                            </td>
                            <td class="td vs-table--td align-top" style="padding: 5px">
                                <vs-input class="w-full" type="text" v-model="itemParent.subTotalText" readonly
                                    :min="0"></vs-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <h3><b>Child (Source)</b></h3>
        <hr />
        <br />
        <div class="vx-row">
            <div class="vx-col sm:w-1/1 w-full mb-base m-10">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="20%">SKU Code</th>
                            <th width="30%">SKU Name</th>
                            <th width="7%">Qty</th>
                            <th width="7%">Unit</th>
                            <th width="7%">Qty UOM</th>
                            <th width="10%">MAP</th>
                            <th width="10%">Batch</th>
                            <th width="10%">Sub Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(child, index) in itemLines" :key="index" style="padding-bottom: 5px">
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input class="w-full" v-model="child.sku_code" disabled />
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input class="w-full" v-model="child.name" disabled />
                            </td>
                            <td class="td vs-table--td" style="padding-right: 10px">
                                <vs-input class="w-full" type="number" v-model="child.quantity" :min="1" disabled />
                            </td>
                            <td class="td vs-table--td" style="padding-right: 5px">
                                <vs-input class="w-full" v-model="child.unit" disabled />
                            </td>
                            <td class="td vs-table--td" style="padding-right: 5px">
                                <vs-input class="w-full" v-model="child.qty_uom" disabled />
                            </td>
                            <td class="td vs-table--td" style="padding-right: 5px">
                                <vs-input class="w-full" type="text" v-model="child.price" disabled />
                            </td>
                            <td class="td vs-table--td" style="padding-right: 5px">
                                <vs-input class="w-full" v-model="child.batch" disabled />
                            </td>
                            <td class="td vs-table--td" style="padding-right: 5px">
                                <vs-input class="w-full" v-model="child.subTotal" disabled />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="20%"></th>
                            <th width="20%"></th>
                            <th width="20%"></th>
                            <th width="10%"></th>
                            <th>TOTAL</th>
                            <th>{{ total }}</th>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
        <br>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="ml-4 mt-2" color="primary" v-on:click="handleAttach()">Attach</vs-button>
                    <vs-button class="ml-2 mt-2" type="border" color="danger" v-on:click="handleBack()">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import moment from "moment";

export default {
    data: () => ({
        id: 0,
        code: "",
        description: "",
        bom: "",
        purpose: "",
        warehouse: "",
        warehouseArea: "",
        requestDate: moment().format("YYYY-MM-DD"),
        executionDate: moment().add(1, "days").format("YYYY-MM-DD"),
        note: "",
        total: "0",
        fileAttachment: [],
        newAttachment: [],
        itemParent: {
            item_unit_id: null,
            sku_code: "",
            name: "",
            unit: "",
            qty: 0,
            qty_old: 0,
            price: 0,
            qty_uom: 0,
            subTotal: 0,
            subTotalText: "0"
        },
        itemLines: [
            {
                id: null,
                item_unit_id: null,
                sku_code: "",
                name: "",
                unit: "",
                qty: 0,
                qty_old: 0,
                qty_uom: 0,
                batch: "",
                price: 0,
                subTotal: 0,
                subTotalText: "0"
            },
        ],
        allowedExtension: ["xlsx", "xls", "jpg", "png", "pdf", "mp4", "avi", "mkv", "3gp", "m4v", "wmv", "mov"]
    }),
    async mounted() {
        this.$vs.loading();
        const id = this.$route.params.id
        this.id = id
        const data = await this.getData(id)
        this.loadData(data)
        this.$vs.loading.close();
    },
    computed: {
        formattedItemParentPrice() {
            return new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 7,
                maximumFractionDigits: 7,
            }).format(this.itemParent.price)
        },
    },
    methods: {
        async handleAttach() {
            const response = await this.attach();
            if (response) {
                if (response.code == 200) {
                    this.$vs.notify({
                        color: "success",
                        title: "Success",
                        text: response.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                    this.handleBack();
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Failed",
                        text: response.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                }
            }

            this.$vs.loading.close();
        },
        attach() {
            if (!this.newAttachment.length) {
                this.$vs.notify({
                    color: "danger",
                    title: "Failed",
                    text: "Please add new Attachment",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });

                return false
            }

            if (this.newAttachment.some(a => !this.allowedExtension.includes(a.NameFile.split(".").at(-1).toLowerCase()))) {
                this.$vs.notify({
                    title: "Error",
                    text: "Invalid attachments extention",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
                return false;
            }

            if (this.newAttachment.length > 0) {
                const formData = new FormData();
                for (let index = 0; index < this.newAttachment.length; index++) {
                    formData.append("attachments[]", this.newAttachment[index].File);
                }
                formData.append("work_order_id", this.id);
                // formData.append("new_attach", true)

                return new Promise((resolve, reject) => {
                    this.$http
                        .post("api/wms/v1/work-order/create-attachment", formData)
                        .then((resp) => {
                            resolve(resp)
                        })
                        .catch((error) => {
                            reject(error)
                        });
                })
            }
        },
        handleBack() {
            this.$router.back()
        },
        getData(id) {
            return new Promise((resolve, reject) => {
                this.$http
                    .get("api/wms/v1/work-order/detail", {
                        params: {
                            id: id,
                        },
                    }).then((resp) => {
                        resolve(resp.data)
                    }).catch((err) => {
                        reject(err)
                    })
            })
        },
        loadData(data) {
            console.log(data)
            const { code,
                description,
                item_bom_code,
                purpose_name,
                warehouse_name,
                request_date,
                execution_date,
                note,
                attachment_data,
                item_parent,
                work_order_lines
            } = data
            this.code = code
            this.description = description
            this.bom = item_bom_code
            this.purpose = purpose_name
            this.warehouse = warehouse_name
            this.requestDate = request_date
            this.executionDate = execution_date
            this.note = note
            this.fileAttachment = attachment_data.map(attachment => ({
                ...attachment
            }))
            this.itemParent = item_parent

            const currUOM = item_parent.qty_uom
            const currMAP = item_parent.price
            const currQTY = item_parent.quantity

            const subTotal = currUOM * currMAP * currQTY

            const subTotalText = new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 7,
                maximumFractionDigits: 7,
            }).format(subTotal)

            this.itemParent.subTotal = subTotal
            this.itemParent.subTotalText = subTotalText

            this.itemLines = work_order_lines.map((item) => ({
                ...item,
                subTotal: item.quantity * item.qty_uom * item.price
            }))

            this.sumTotal()
        },
        sumTotal() {
            const arrOfSubTotal = this.itemLines.map(item => item.quantity * item.qty_uom * item.price)
            const total = arrOfSubTotal.reduce((a, b) => a + b, 0)
            const totalText = new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 7,
                maximumFractionDigits: 7,
            }).format(total)
            this.total = totalText
        },
        handleAttachment() {
            if (this.$refs.file.files.length > 0) {
                this.file = this.$refs.file.files;
                for (let i = 0; i < this.$refs.file.files.length; i++) {
                    this.fileAttachment.push({
                        file_name: this.file[i].name,
                        file: this.file[i],
                        new_attachment: true
                    })
                    this.newAttachment.push({
                        NameFile: this.file[i].name,
                        File: this.file[i],
                    });
                }
                document.querySelector("#fileInput").value = "";
            } else {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select file to import.",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
            }
        },
        handleShowAttachment(file) {
            let objectURL;
            objectURL = file.file_url ? file.file_url : URL.createObjectURL(file.file);

            // link.download = file.name; // this name is used when the user downloads the file
            var href = objectURL;
            window.open(href, "_blank").focus();
        },
        handleDeleteAttachment(index) {
            this.fileAttachment = this.fileAttachment.filter((r, i) => {
                return i != index;
            });

            this.newAttachment = this.fileAttachment.filter(attachment => attachment.new_attachment).map(item => ({
                ...item,
                NameFile: item.file_name
            }))
        },
    }
}
</script>